.customSwitch {
    &::before {
        position: absolute;
        display: block;
        content: "";
        height: 22px;
        width: 22px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        border-radius: 100%;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
}

.customSwitchContainer{
  display: flex;
  align-items: center;
  height: 100%;
    input:checked + .customSwitch {
        background-color: #2196F3;
      }
      
      input:focus + .customSwitch {
        box-shadow: 0 0 1px #2196F3;
      }
      
      input:checked + .customSwitch:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
      }
}
.permissionDropdown{
  // button{
 // min-width: 220px;
  // }
  span{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.projectTable {
    position: relative;

    td {
        font-weight: normal;
        // width: 200px;
        // min-width: 200px;
        // max-width: 249px;
        left: unset;

        >div {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            height: 100%;

            >div {
                height: 100%;
                display: flex;
                align-items: center;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                max-height: 35px !important;
            }
        }

        // >div:first-child{
        //    // min-width: 100% !important;
        //   //  max-width: 100% !important;
        // }
    }

    td[id*="race"] {
        >div>div {
            display: flex;
            align-items: center;
        }
    }

    // td[id*="gender"]{
    //     max-width: 100px !important;
    //     min-width: 100px !important;
    //     >div>div{
    //         max-width: 100% !important;
    //     }
    // }
    // td[id*="nationality"],
    // td[id*="status"]{
    //     max-width: 130px !important;
    //     min-width: 130px !important;
    //     >div>div{
    //         max-width: 100% !important;
    //     }
    // }
    // td[id*="designation"],
    // td[id*="currency_id"],   
    // td[id*="department"]{
    //     max-width: 218px !important;
    //     min-width: 218px !important;
    //     >div>div{
    //         max-width: 100% !important;
    //     }
    // }
    // th {
    //     // width: 200px;
    //     // >div{
    //     //    // max-height: 43px !important;
    //     // }
    // }
    th[id*="actions"] {
        >div {
            max-height: 45px !important;
        }
    }

    // input[type="number"],
    // input[type="email"]{
    //    // background-color: transparent;
    // }
    input[type="text"] {
        width: 100%;
        // background-color: transparent;
    }

    th[id*="lasted"],
    td[id*="lasted"] {
        min-width: 141px !important;
        width: 141px;

        >div {
            height: 100%;
        }
    }

    th[id*="actions"],
    td[id*="actions"] {
        min-width: 140px !important;
        width: 140px;
        position: absolute !important;
        right: 0;
        left: unset !important;
        background-color: #fff;
        z-index: 1;
    }

    //   td[id*="employeeID"],
    //   td[id*="age"]{
    //       padding-left: 0 !important;
    //       padding-right: 0 !important;
    //   }
}

.userTable {
    position: relative;

    td {
        font-weight: normal;
        // width: 200px;
        // min-width: 200px;
        // max-width: 249px;
        left: unset;

        >div {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            height: 100%;

            >div {
                height: 100%;
                display: flex;
                align-items: center;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                max-height: 35px !important;
            }
        }

        // >div:first-child{
        //    // min-width: 100% !important;
        //   //  max-width: 100% !important;
        // }
    }

    td[id*="race"] {
        >div>div {
            display: flex;
            align-items: center;
        }
    }

    // td[id*="gender"]{
    //     max-width: 100px !important;
    //     min-width: 100px !important;
    //     >div>div{
    //         max-width: 100% !important;
    //     }
    // }
    // td[id*="nationality"],
    // td[id*="status"]{
    //     max-width: 130px !important;
    //     min-width: 130px !important;
    //     >div>div{
    //         max-width: 100% !important;
    //     }
    // }
    // td[id*="designation"],
    // td[id*="currency_id"],   
    // td[id*="department"]{
    //     max-width: 218px !important;
    //     min-width: 218px !important;
    //     >div>div{
    //         max-width: 100% !important;
    //     }
    // }
    // th {
    //     // width: 200px;
    //     // >div{
    //     //    // max-height: 43px !important;
    //     // }
    // }
    th[id*="actions"] {
        >div {
            max-height: 45px !important;
        }
    }

    // input[type="number"],
    // input[type="email"]{
    //    // background-color: transparent;
    // }
    input[type="text"] {
        width: 100%;
        // background-color: transparent;
    }

    th[id*="lasted"],
    td[id*="lasted"] {
        min-width: 141px !important;
        width: 141px;

        >div {
            height: 100%;
        }
    }

    th[id*="actions"],
    td[id*="actions"] {
        min-width: 151px !important;
        width: 151px !important;
        position: sticky !important; //absolute
        right: 0;
        left: unset !important;
        background-color: #fff;
        z-index: 1;
    }

    td[id*="employeeID"],
    td[id*="age"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.selectedMember {
    background-color: #E1F0FF !important;
}

.singleDropdown {
    width: 100%;
}

.projectTable {
    td>div>button {
        height: 100% !important;
    }

    td[id*="projectCode"],
    td[id*="projectCompleted"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.customCloseIcon {
    &:hover {
        svg path {
            stroke: #EE436D;
        }
    }
}


.updatedProjectBar {
    // position: relative;

    &::after {
        display: block;
        content: '';
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-left: 18px solid transparent;
        border-top: 18px solid #FFC84A;
        border-right: 8px solid #FFC84A;
        border-bottom: 18px solid #FFC84A;
        position: absolute;
        top: -1;
        left: 70;
        z-index: 1,
    }
}
.customCloseIcon{
  &:hover{
      svg path{
          stroke: #EE436D;
      }
  }
}
.customUploadBtn{
    background-color: rgba(0, 0, 0, 0.2);
    svg{
      width: 100%;
      height: 100%;
      transform: scale(3);
    }
  }
  .profileImageDiv{
    &:hover{
      .customUploadBtn{
        display: block;
      }
    }
  }

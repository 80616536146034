.customInfoBox {
    border-radius: 12px;
    box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.12);

    ul::-webkit-scrollbar {
        width: 3px;
        right: 10px;
    }

    ul::-webkit-scrollbar-thumb {
        background: #858795;
        border-radius: 3px;


    }

    ul::-webkit-scrollbar-track {
        border-radius: 15px;
        margin-right: 40px;
        box-shadow: inset 7px 10px 12px #f0f0f0;

    }

    .redDot {
        display: flex;
        position: relative;

        &:after {
            position: absolute;
            top: 16px;
            right: 20px;
            display: block;
            content: '';
            width: 7px;
            height: 7px;
            border-radius: 100%;
            background-color: #EE436D;
        }
    }
}
.EmployeeDocumentsTable {
    position: relative;
    th[id*="lasted"],
    td[id*="lasted"],
    th[id*="actions"],
    td[id*="actions"] {
        width: 150px;
        max-width: 150px !important;
        min-width: 150px !important;
    }
    td[id*="actions"] {
        min-height: 35px !important;
    }
    th[id*="name"],
    td[id*="name"]{
        max-width: 250px !important;
        //overflow: hidden;
    }
    td[id*="code"],
    td[id*="name"],
    td[id*="path"],
    td[id*="lastModifiedAt"],
    td[id*="postedBy"]
    {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

td .actionedBtn {
    margin: 0 auto;

    button {
        width: 24px !important;
        height: 24px !important;
    }
}
.goToTop {
	animation: goToTop .3s ease;
}

@keyframes goToTop {
	0% {
		transform: translateY(-100px);
	}

	100% {
		transform: translateY(0%);
	}
}

.numberSlot {
	display: flex !important;
	flex-direction: column !important;
	height: 22px !important;
	width: 65px !important;
}

@media only screen and (max-width: 1000px) {
	.numberSlot {
		width: 50px !important;
		font-size: 12px !important;
	}
}


.sliderInput {
	// -webkit-appearance: none;
	// width: 100%;
	// height: 10px;
	// border-radius: 5px;
	// background: #3699FF;
	// outline: none;
	// -webkit-transition: .2s;
	// transition: opacity .2s;
	background: linear-gradient(to right, #3699FF 0%, #3699FF 0%, #E1F0FF 0%, #E1F0FF 100%);
	//border: solid 1px #82CFD0;
	border-radius: 8px;
	height: 7px;
	// width: 115px;
	// width: 356px;
	outline: none;
	transition: background 450ms ease-in;
	-webkit-appearance: none;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background: #3699FF;
		cursor: pointer;
		border: 3px solid #E1F0FF;
	}

	// &::-webkit-slider-runnable-track {
	// 	-webkit-appearance: none;
	// 	color: #3699FF;
	// 	margin-top: -1px;
	//   }
	// &::-moz-range-progress {
	// 	background-color: #3699FF; 
	//   }
	//   &::-moz-range-track {  
	// 	background-color: #3699FF;
	//   }
	//   /* IE*/
	//   &::-ms-fill-lower {
	// 	background-color: #3699FF; 
	//   }
	//   &::-ms-fill-upper {  
	// 	background-color: #3699FF;
	//   }
}


// @media screen and (-webkit-min-device-pixel-ratio:0) {
// 	input[type='range'] {
// 		overflow: hidden;
// 		width: 80px;
// 		-webkit-appearance: none;
// 		background-color: #E1F0FF;
// 	}

// 	input[type='range']::-webkit-slider-runnable-track {
// 		height: 10px;
// 		-webkit-appearance: none;
// 		color: #3699FF;
// 		margin-top: -1px;
// 	}

// 	input[type='range']::-webkit-slider-thumb {
// 		width: 18px;
// 		-webkit-appearance: none;
// 		height: 18px;
// 		cursor: pointer;
// 		border-radius: 50%;
// 		background: #3699FF;
// 		box-shadow: -80px 0 0 80px #3699FF;
// 		border: 3px solid #E1F0FF;
// 	}

// }

// /** FF*/
// input[type="range"]::-moz-range-progress {
// 	background-color: #3699FF;
// }

// input[type="range"]::-moz-range-track {
// 	background-color: #E1F0FF;
// }

// /* IE*/
// input[type="range"]::-ms-fill-lower {
// 	background-color: #3699FF;
// }

// input[type="range"]::-ms-fill-upper {
// 	background-color: #E1F0FF;
// }
.TimetrackingChart{
    canvas{
        transform: rotate(90deg);
        margin: 0 auto;
        width: 100% !important;
        height: 100% !important;
    }
    @media screen and (max-width:999px) {
        max-width: 50%;
    }
    @media screen and (max-width:571px) {
        max-width: 80%;
    }
}
.employeeListDropdown{
    min-width: 220px;
    button{
        font-size: 0.875rem;
    }
    @media screen and (max-width:571px) {
        min-width: max-content;
    }
}
.employeeData{
    &::after{
        height: 100%;
        width: 2px;
        background-color: #F3F6F9;
        display: block;
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        @media screen and (max-width:571px) {
            display: none;
        }
    }
    @media screen and (max-width:571px) {
        width: 100%;
    }
}
.scrollHide::-webkit-scrollbar{
    display: none;
}
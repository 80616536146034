@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

:root {
  --font-ratio: 1;
  --text-24: 24px;
  --text-22: 22px;
  --text-20: 20px;
  --text-18: 18px;
  --text-16: 16px;
  --text-15: 15px;
  --text-14: 14px;
  --text-13: 13px;
  --text-12: 12px;
  --text-10: 10px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.e-contextmenu-wrapper ul .e-menu-item,
.e-contextmenu-container ul .e-menu-item,
.e-dropdown-popup ul .e-item {
  font-family: 'Poppins', sans-serif;
}

.react-datepicker-popper {
  z-index: 99 !important;
}

body *,
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  font-family: 'Arial', sans-serif;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  span {
    font-family: 'Arial', sans-serif;
  }
}

.ot_modal {
  .customDropdownContainer {
    .absolute {
      z-index: unset !important;
    }
  }
}

.customDropdownContainer {
  .absolute {
    z-index: 9999999 !important;
  }
}

.dx-filemanager-thumbnails-item-thumbnail,
.dx-filemanager-dirs-tree.dx-treeview .dx-treeview-item-content>i {
  &::before {
    content: url('../public/img/flat-color-icons_folder.svg');
    display: block;
  }
}

// .dx-filemanager-thumbnails-item-thumbnail,
.dx-treeview-item li.dx-treeview-node[aria-expanded="true"] {
  &::before {
    content: url('../public/img/material-symbols_folder-open.svg') !important;
    display: block;
  }
}

.dx-treeview-item .dx-treeview-item-content>.dx-icon {
  height: auto !important;
}

.dx-filemanager-dirs-tree .dx-treeview-item-content .dx-filemanager-dirs-tree-item-text {
  text-align: left;
  margin-left: 12px;
  font-size: 0.875rem;
  font-weight: normal;
  color: #464E5F !important;
}

.dx-treeview-node-container-opened .dx-treeview-node[aria-expanded="true"] {
  >.dx-treeview-item>.dx-item-content>.dx-icon-folder {
    &::before {
      content: url('../public/img/material-symbols_folder-open.svg') !important;
      display: block;
    }
  }
}

.dx-filemanager-view-switcher-popup .dx-dropdownbutton-popup-wrapper.dx-dropdownbutton-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dx-toolbar-after .dx-icon-mediumiconslayout::before {
  content: url('../public/img/filemanager-grid-view-icon.svg') !important;
  display: block;
}

.dx-toolbar-after .dx-icon-detailslayout::before {
  content: url('../public/img/filemanager-detail-view-icon.svg') !important;
  display: block;
}

.dx-filemanager .dx-filemanager-thumbnails .dx-filemanager-thumbnails-view-port .dx-filemanager-thumbnails-item {
  border: 1px solid #DADADA !important;
  margin: 15px 15px !important;
  width: 104px !important;
  height: 104px !important;
}

.dx-filemanager .dx-filemanager-thumbnails .dx-filemanager-thumbnails-view-port .dx-filemanager-thumbnails-item .dx-filemanager-thumbnails-item-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0 !important;
}

.dx-filemanager-thumbnails-item-thumbnail {
  margin: 0 auto;
}

.dx-filemanager .dx-filemanager-thumbnails .dx-filemanager-thumbnails-view-port .dx-filemanager-thumbnails-item .dx-filemanager-thumbnails-item-content .dx-filemanager-thumbnails-item-name {
  border: 1px solid #DADADA !important;
  padding: 4px !important;
  font-size: 0.875rem !important;
  color: #464E5F !important;
}

.fc-h-event {
  border: 0;
  // background-color: unset !important;
  // border: none !important;
}

table {
  th.absolute {
    // min-height: 45px !important;
    // height: 45px !important;
    background-color: white;
    padding: 0;
    border: none;

    //z-index: 1;
    >div {
      z-index: 1;
      position: relative;
      padding: 0.75rem;
      padding-left: 1rem;
      padding-right: 1rem;
      background-color: #f5f8fa;
      border: 1px solid #C4C4C4;
      border-bottom: none;

      ~div.fixed {
        z-index: 9;
      }
    }
  }

  td.absolute {
    min-height: 35px !important;
    height: 35px !important;
    background-color: white;
    z-index: 7;
  }

  // th>div {
  //min-height: 44px;
  //padding: 0.45rem !important;
  // }

  #none {
    &0 {
      width: 300px;
      min-width: 300px;
    }
  }

  th.absolute {
    >div:first-child {
      display: flex;
      align-items: center;
      padding: 0;
      height: 100%;
    }
  }

  th:last-child[id*="actions"],
  td:last-child[id*="actions"] {
    min-width: 120px;
    width: 120px;
    position: sticky !important;
    right: 0;
    outline: 1px solid rgb(194, 197, 198);
  }

  td {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // padding-left: 0.5rem;
    // padding-right: 0.5rem;
  }
}

.PermissionTable {
  th.absolute {
    >div {
      height: 52px;
    }
  }
}

.PermissionTable_PermissionTable__s4HzW .Tables_horizontalScrollBar__PfS6H {
  // th.absolute {
  //   // min-height: 69px !important;
  //   // height: 69px !important;
  //   // min-width: max-content;
  //   // width: auto;
  // }

  td.absolute {
    min-height: 50px !important;
  }

  th,
  td {
    max-width: 132px !important;
    outline: 1px solid rgb(194, 197, 198);
  }
}

.EmployeeTable_employeeTable__DmL8R {

  th[id*="lasted"],
  td[id*="lasted"] {
    min-width: 170px !important;
    width: 170px;

    >div {
      height: 100%;
    }
  }

  th[id*="actions"],
  td[id*="actions"] {
    min-width: 170px !important;
    width: 170px;
    position: sticky !important;
    right: 0;
    left: unset !important;
  }
}

.fc .fc-popover-header {
  height: auto !important;
}

.fc .fc-more-popover .fc-popover-body {
  background-color: #fff;

  .stickerNote {
    display: none;
  }
}

td>div>div.hidden {
  display: none !important;
}

table {

  th[id*="enable"],
  td[id*="enable"] {
    min-width: 100% !important;
    max-width: 100% !important;

    >div {
      min-width: 100% !important;
      max-width: 100% !important;

      >div {
        min-width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
}

// .fc .fc-daygrid-day-frame {
//   // height: 125px !important;
// }

.fc-daygrid-event-harness {
  margin-top: 0 !important;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
}

.annoucement-modal .custom-padding {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.customEventContainer {
  background-color: transparent;
}


th[id*="projectCode"],
td[id*="projectCode"] {
  div {
    display: flex;
    align-items: center;
    justify-content: left;
  }
}

.poppuerCustom {
  z-index: 9999;
  //9999
}

th[id*="actions"] {
  >div {
    min-height: 30px !important;
  }
}

.tox-notifications-container {
  display: none;
}

.profileDocTable,
.EmployeeDocumentsTable {
  th[id*="actions"] {
    height: 30px !important;
  }

  th[id*="lasted"],
  td[id*="lasted"] {
    min-width: 120px;
    max-width: 120px;
  }
}

.position-unset {
  position: unset !important;
}


.custom-preview:not(:first) {
  display: none;
}

.popup-scrollbar {

  &::-webkit-scrollbar {
    width: 100%;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #B5B5C3;
    border-radius: 3px;

  }

  scrollbar-width: thin;
  scrollbar-color: #B5B5C3 transparent;

  :-webkit-scrollbar {
    width: 100%;
  }

  :-webkit-scrollbar-track {
    background: transparent;
  }

  :-webkit-scrollbar-thumb {
    width: 3px;
    background-color: #B5B5C3;
    border-radius: 3px;
    border: 3px solid transparent;
  }
}

.custom-scrollbar {
  >div {
    min-height: 28px;
    // min-height: 31px;
  }

  &::-webkit-scrollbar {
    width: 100%;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #B5B5C3;
    border-radius: 3px;

  }

  scrollbar-width: thin;
  scrollbar-color: #B5B5C3 transparent;

  :-webkit-scrollbar {
    width: 100%;
  }

  :-webkit-scrollbar-track {
    background: transparent;
  }

  :-webkit-scrollbar-thumb {
    background-color: #B5B5C3;
    border-radius: 3px;
    border: 3px solid transparent;
  }

  /* webkit browsers */
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    height: 3px;
    border-radius: 3px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
  }
}

.doc-scrollbar.custom-scrollbar {
  >div {
    min-height: 36px;
  }
}

table {

  input[type="number"],
  input[type="email"],
  input[type="text"] {
    width: 100%;
    //background-color: transparent;
  }
}

.fc-prevCustomButton-button:hover .fc-icon,
.fc-nextCustomButton-button:hover .fc-icon {
  // color: white !important;
}

.fc-today-button {
  text-transform: capitalize !important;
}

// .dashboardCalendar .fc-toolbar-chunk {
//   margin-left: -18%;
//   @media screen and (max-width:641px) {
//     margin-left: 0%;
//   }
// }
.modalContent {
  z-index: 1;
  animation: blowUpContent .4s cubic-bezier(0.165, 0.840, 0.440, 1.000) backwards;
}

@keyframes blowUpContent {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.tox-statusbar__branding {
  display: none !important;
}

.tox.tox-tinymce {
  height: 91% !important;
  min-height: 200px !important;
}

audio::-webkit-media-controls-panel {
  background-color: #fff;
}

audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
  display: block;
}

audio::-webkit-media-controls-enclosure,
video::-webkit-media-controls-enclosure {
  height: 20px;
}

audio::-webkit-media-controls-play-button {
  background-color: #3699FF;
  border-radius: 50%;
}

audio::-webkit-media-controls-volume-control-container {
  display: none;
}

audio::-webkit-media-controls-timeline {
  color: blue;
  border: 3px solid blue;
}

:root {
  --font-15: 15px;
  --font-14: 14px;
  --font-18: 18px;
  --font-24: 24px;
}

@media (max-width: 1281px) {
  :root {
    --font-15: 12px;
    --font-14: 14px;
    --font-18: 14px;
    --font-24: 20px;
  }
}

@media (max-width: 1025px) {
  :root {
    --font-15: 12px;
    --font-14: 12px;
    --font-18: 14px;
    --font-24: 20px;
  }
}

.text-15 {
  font-size: var(--font-15) !important;
}

.text-14 {
  font-size: var(--font-14) !important;
}

.text-18 {
  font-size: var(--font-18) !important;
}

.text-24 {
  font-size: var(--font-24) !important;
}

.progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
}

.progress::-webkit-slider-runnable-track {
  height: 5px;
  background: #ccc;
  border-radius: 0;
}

.progress::-moz-range-track {
  height: 5px;
  background: #ccc;
  border-radius: 0;
}

.progress::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  width: 5px;
  background-color: #3699FF;
  border-radius: 0%;
  border: 5px solid #3699FF;
  box-shadow: -407px 0 0 400px #3699FF;
}

.progress::-moz-range-thumb {
  height: 5px;
  width: 5px;
  background-color: #3699FF;
  border: 5px solid #3699FF;
  box-shadow: -407px 0 0 400px #3699FF;
}










.audio-player {
  --player-button-width: 3em;
  --sound-button-width: 2em;
  --space: .5em;
  // width: 15rem;
  // height: 15rem;
}

.icon-container {
  width: 100%;
  height: 100%;
  background-color: #3699FF;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audio-icon {
  width: 90%;
  height: 90%;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.player-button {
  background-color: transparent;
  border: 0;
  width: var(--player-button-width);
  height: var(--player-button-width);
  cursor: pointer;
  padding: 0;
}

.timeline {
  -webkit-appearance: none;
  appearance: none;
  width: calc(100% - (var(--player-button-width) + var(--sound-button-width) + var(--space)));
  height: .5em;
  background-color: #e5e5e5;
  border-radius: 5px;
  background-size: 0% 100%;
  background-image: linear-gradient(#3699FF, #3699FF);
  background-repeat: no-repeat;
  margin-right: var(--space);
}

.timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all .1s;
  background-color: #3699FF;
}

.timeline::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all .1s;
  background-color: #3699FF;
}

.timeline::-ms-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all .1s;
  background-color: #3699FF;
}

// .timeline::-webkit-slider-thumb:hover {
//background-color: #3699FF;
// }

.timeline:hover::-webkit-slider-thumb {
  opacity: 1;
}

// .timeline::-moz-range-thumb:hover {
//background-color: #3699FF;
// }

.timeline:hover::-moz-range-thumb {
  opacity: 1;
}

.timeline::-ms-thumb:hover {
  background-color: #3699FF;
}

.timeline:hover::-ms-thumb {
  opacity: 1;
}

.timeline::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.timeline::-moz-range-track {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.timeline::-ms-track {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.tooltip-z {
  z-index: 22 !important;
}

audio::-webkit-media-controls-volume-slider,
audio::-webkit-media-controls-timeline,
audio::-webkit-media-controls-volume-control-container {
  display: none;
}

.tox .tox-listboxfield .tox-listbox--select,
.tox .tox-textarea,
.tox .tox-textarea-wrap .tox-textarea:focus,
.tox .tox-textfield,
.tox .tox-toolbar-textfield {
  font-family: 'Poppins', sans-serif !important;
}

.tox-dialog__footer button,
.textarea-wrap textarea,
.tox-dialog__title {
  font-family: 'Poppins', sans-serif !important;
}

.fc-theme-standard .fc-popover {
  height: max-content !important;
  max-height: max-content !important;
}

.preview {

  ol,
  ul {
    list-style: unset;
  }
}

.profile_calendar .fc .fc-daygrid-day-frame {
  display: block !important;
  cursor: pointer;
  pointer-events: none;
}

.fc-daygrid-day-events {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;

  .fc-daygrid-event-harness:nth-child(1) {
    @media screen and (max-width:641px) {
      .mobile-calendar-event {
        display: block;
        width: 80px;
      }

      // display: none;      
    }

    @media screen and (max-width:426px) {
      .mobile-calendar-event {
        width: 50px;
      }
    }

    @media screen and (max-width:400px) {
      .mobile-calendar-event {
        width: 46px;
      }
    }

    @media screen and (max-width:376px) {
      .mobile-calendar-event {
        width: 41px;
      }
    }
  }

  .mobile-calendar-event {
    display: none;
  }

  @media screen and (max-width:641px) {
    .fc-daygrid-event-harness:nth-child(1) {
      z-index: 4;
    }

    .fc-daygrid-event-harness {
      z-index: -1;
    }
  }

  .fc-more-link {
    @media screen and (max-width:641px) {
      display: none;
    }
  }
}

.fc .fc-daygrid-day-number {
  @media screen and (max-width:641px) {
    z-index: 1;
  }

}

.fc-event {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.fc-daygrid-day-events {
  margin-bottom: 0 !important;

  li {
    list-style: none !important;
  }

  .fc-daygrid-event-harness:nth-child(2) {
    .eventContent~.stickerNote {
      top: 60px;
    }

    .stickerNote {
      //top: 60px;
      top: 78px;
    }
  }
}

.eventContent {
  @media screen and (max-width:641px) {
    display: none;
  }
}

.fc .fc-toolbar-chunk:nth-child(3) {
  button {
    background-color: #009ef7 !important;
    color: white !important;
    border-radius: 6px !important;
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }


}

.fc .fc-toolbar {
  @media screen and (max-width:641px) {
    flex-wrap: wrap !important;

    .fc-toolbar-chunk:nth-child(1) .fc-button-group~button {
      display: none;
    }
  }
}

table td {
  border: 1px solid black;
  padding: 5px;
  line-height: normal;
}

.slick-dots {
  bottom: 4px !important;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
}

.accordion-indicatior {
  @media screen and (max-width:639px) {
    display: flex;
    width: 100%;

    &.active {
      ~div svg {
        transform: rotate(180deg);
      }
    }
  }
}

.indicator-collpase {
  display: flex;

  @media screen and (max-width:481px) {
    display: none;
    transition: all .5s;

    &.active {
      display: block;
    }
  }
}

body {
  // -webkit-user-select: none;
  // /* Safari */
  // -ms-user-select: none;
  // /* IE 10 and IE 11 */
  // user-select: none;
  /* Standard syntax */
}

.modalContent {

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

.custom-dropdown-container {
  &.showArrow {
    border: 0;

    svg {
      opacity: 0;
    }
  }

  &:focus,
  &:focus-visible {
    &.showArrow {
      border: 1px solid #C4C4C4;

      svg {
        opacity: 1;
      }
    }
  }

}

a {
  word-break: break-word !important;
}

.custom-exchange-input {
  position: relative;
  max-width: 100px;

  &::after {
    content: '';
    display: block;
    height: 70%;
    width: 1px;
    background-color: #B5B5C3;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  width: 100% !important;
  min-width: 200px !important;
}

.react-datepicker__input-container input {
  //  display: none !important;
  background-color: transparent;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
  }
}

.generate_popup .react-datepicker__month-container {
  max-width: 300px;
  max-height: 230px;
  overflow: auto;
}

.react-datepicker__month-wrapper {
  display: flex;
  flex-direction: column;
}

.react-datepicker-popper[data-placement^=bottom] {
  width: auto !important;
}

#spring-popper .react-datepicker__input-container input {
  display: none;
}

#spring-popper .react-datepicker__close-icon {
  display: none;
}

#spring-popper {
  .react-datepicker-popper[data-placement^=bottom] {
    top: 2.5rem !important;
  }

  .react-datepicker-popper[data-placement^=bottom] {
    padding-top: 0;
  }
}

#spring-popper[role=tooltip] {
  z-index: 999999 !important;
}

.custom-month-picker {
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #464E5F;
  }

  .react-datepicker__input-container input,
  .react-datepicker__input-container,
  .react-datepicker-wrapper {
    min-height: 50px;
  }
}

.react-datepicker__input-container {
  input {
    width: 100%;
  }
}



// .number-slot {}

.number-slot .number {
  position: relative;
}

@keyframes moveNumberIn {
  0% {
    margin-top: -1.2em;
  }

  30% {
    margin-top: -1.2em;
  }

  100% {
    margin-top: 0em;
  }
}

@keyframes moveNumberOut {
  0% {
    margin-top: 0em;
  }

  30% {
    margin-top: -1.2em;
  }

  100% {
    margin-top: -1.2em;
  }
}

.move-number-out {
  animation: moveNumberOut .5s;
}

.move-number-in {
  animation: moveNumberIn .5s;
}

.number-slot {
  display: flex;
  flex-direction: column !important;
}

// @media screen and (-webkit-min-device-pixel-ratio:0) {
//   input[type='range'] {
//     overflow: hidden;
//     width: 80px;
//     -webkit-appearance: none;
//     background-color: #E1F0FF;
//   }

//   // input[type='range']::-webkit-slider-runnable-track {
//   //   height: 10px;
//   //   -webkit-appearance: none;
//   //   color: #13bba4;
//   //   margin-top: -1px;
//   // }

//   input[type='range']::-webkit-slider-thumb {
//     width: 18px;
//     -webkit-appearance: none;
//     height: 18px;
//     cursor: pointer;
//     //background: #434343;
//     box-shadow: -80px 0 0 80px #3699FF;
//   }

// }

// /** FF*/
// input[type="range"]::-moz-range-progress {
//   background-color: #3699FF;
// }

// input[type="range"]::-moz-range-track {
//   background-color: #E1F0FF;
// }

// /* IE*/
// input[type="range"]::-ms-fill-lower {
//   background-color: #3699FF;
// }

// input[type="range"]::-ms-fill-upper {
//   background-color: #E1F0FF;
// }
div.MuiPopper-root[role=tooltip] {
  z-index: 9999 !important;
}

div.MuiModal-root[role=presentation] {
  z-index: 9999999 !important;
}

// .custom_scrollbar_popup {
//   //margin-right: 5px;
// }

.custom_scrollbar_popup::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.custom_scrollbar_popup::-webkit-scrollbar-track {
  // background: #CACACA; 
  box-shadow: inset 0 0 10px 10px #CACACA;
  border: solid 3px transparent;
}

/* Handle */
.custom_scrollbar_popup::-webkit-scrollbar-thumb {
  //  background: #508ef2; 
  height: 50px;
  //   border: 4px solid transparent;
  //   background-clip: padding-box;
  box-shadow: inset 0 0 10px 10px #A7A7A7;
  border: solid 3px transparent;
}

/* Handle on hover */
.react-tel-input .country-list .country {
  text-align: left !important;
}

.custom_scrollbar_popup_x::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: #CACACA;
}

/* Track */
.custom_scrollbar_popup_x::-webkit-scrollbar-track {
  background: #CACACA;
}

/* Handle */
.custom_scrollbar_popup_x::-webkit-scrollbar-thumb {
  background: #CACACA;
}

/* Handle on hover */
.custom_scrollbar_popup_x::-webkit-scrollbar-thumb:hover {
  background: #CACACA;
}

.custom_scrollbar_popup_x::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  background: #508ef2;
}

.project_popup {
  .custom-button-size .text-12 {
    font-size: 14px !important;
  }

  //.text-12
  label {
    font-size: 15px !important;

    @media screen and (max-width:1025px) {
      font-size: 12px !important;
    }
  }
}

.tox-tinymce-aux,
.tox-tinymce-aux {
  z-index: 9999999;
}

.tox-notifications-container {
  display: none !important;
}

.words-break {
  word-break: break-word;
}

.daily_report_dropdown {
  background-color: #fff;

  @media screen and (max-width:999px) {
    button {
      max-width: 100%;
      text-align: left;
    }
  }
}

.own_project {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-top: 28px solid transparent;
    border-bottom: 28px solid transparent;
    border-right: 28px solid #FFC84A;
    transform: rotate(45deg);
    position: absolute;
    top: -18px;
    left: -4px;
  }
}


div.MuiModal-root[role=presentation].project_popup {
  z-index: 9999999 !important;

  >div:focus-visible,
  >div:focus-within,
  &:focus-visible,
  &:focus-within {
    outline: none !important;
  }
}

.custom_keydate_dropdown {
  button {
    border: 0;
  }
}

.custom_table_timesheet {

  th,
  td {
    border: 0 !important;
    text-align: left !important;
  }
}

.orange_bg {
  >div {
    border: 0.5px solid rgba(255, 200, 74, 0.40);
    border-bottom: 0;

    &:last-child {
      border-bottom: 0.5px solid rgba(255, 200, 74, 0.40);
    }
  }
}



.Toastify__toast-container--top-center {
  position: relative;
  z-index: 9999999 !important;
}

.Toastify__toast-container--top-right {
  position: relative;
  z-index: 9999999 !important;
}

.react-datepicker {
  // position: relative;
  // z-index: 99999999 !important;
  // opacity: 1 !important;
  // overflow: visible !important;
}

.react-datepicker-popper .react-datepicker__month-container {
  // position: relative;
  // z-index: 99999999 !important;
  // opacity: 1 !important;
  // overflow: visible !important;
}

.react-datepicker__day--keyboard-selected {
  // background-color: green  !important;
}


.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #009EF7 !important;
  color: white !important;
}

.custom_table_timesheet {
  @media screen and (max-width:641px) {
    th {
      display: none;
    }

    tr {
      display: block;
    }

    td {
      display: flex;
      width: 100%;
      font-size: 12px;

      &::before {
        width: 50%;
        content: attr(headers);
        display: block;
        padding-right: 10px;
        font-size: 12px;
        white-space: nowrap;
      }
    }
  }
}

.timebutton {
  &.bg-vorpblue:hover {
    svg {
      path {
        fill: #3699FF;
      }
    }
  }

  &.bg-redPink:hover {
    svg {
      path {
        fill: #EE436D;
      }
    }
  }
}

.custom-scrollbar .dropdown-hover-btn:hover {
  border-color: #3699FF;
  transition: all .3s;
  color: #3699FF;

  span,
  p {
    color: #3699FF;
    transition: all .3s;
  }

  svg {
    path {
      fill: #3699FF;
      transition: all .3s;
    }
  }
}

.body-15 {
  font-size: 15px;
}

.body-24 {
  font-size: var(--text-24);

  @media screen and (max-width:999px) {
    font-size: calc(var(--text-24)*0.8);
  }
}

.body-22 {
  font-size: var(--text-22);

  @media screen and (max-width:999px) {
    font-size: calc(var(--text-22)*0.8);
  }
}

.body-20 {
  font-size: var(--text-20);

  @media screen and (max-width:999px) {
    font-size: calc(var(--text-20)*0.8);
  }
}

.body-18 {
  font-size: var(--text-18);

  @media screen and (max-width:999px) {
    font-size: calc(var(--text-18)*0.8);
  }
}

.body-16 {
  font-size: var(--text-16);

  @media screen and (max-width:999px) {
    font-size: calc(var(--text-16)*0.8);
  }
}

.body-15 {
  font-size: var(--text-15);

  @media screen and (max-width:999px) {
    font-size: calc(var(--text-15)*0.8);
  }
}

.body-14 {
  font-size: var(--text-14);

  @media screen and (max-width:999px) {
    font-size: 12px;
  }
}

.saveButton {
  border: 1px solid #3699FF;
  transition: all .3s;

  &:hover {
    background-color: #fff !important;
    color: #3699FF !important;

    svg path {
      fill: #3699FF;
    }
  }
}

.backButton {
  border: 1px solid #f3f6f9;
  transition: all .3s;

  &:hover {
    background-color: #464E5F;
    color: #fff;

    svg path {
      fill: #fff;
    }
  }
}

.return-btn {
  border: 1px solid #FFA800;

  &:hover {
    path {
      fill: #FFA800;
    }
  }
}

.apply-btn {
  border: 1px solid #4FC9DA;

  &:hover {
    path {
      fill: #4FC9DA;
    }
  }
}

.equipment-assign-date {
  s svg {
    width: 24px;
    height: 24px;
  }

  span~.px-\[14px\].py-3 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.equipment-borrow-date {
  .min-w-\[180px\] {
    padding-left: 8px;
    padding-right: 8px;
  }

  .border-\[\#CCDAE4\] {
    border: 1px solid #B5B5C3;
  }
}


.sliderNextBack {
  .slick-next {
    right: -25 !important;
    // background-color: red !important;
    // color: blue !important;

    &::before {
      color: #B5B5C3 !important;
    }
  }

  .slick-prev {
    right: 0 !important;

    // background-color: red !important;
    // color: blue !important;
    &::before {
      color: #B5B5C3 !important;
    }
  }
}

.screenshot-slider2 {

  .slick-prev,
  .slick-next {
    width: 32px;
    height: 32px;
    transition: all .3s;

    &:hover {
      &::before {
        background-color: #009EF7;
        color: #fff;
      }
    }
  }

  &:hover {
    .slick-prev {
      left: -40px;
      opacity: 1;
    }

    .slick-next {
      right: -40px;
      opacity: 1;
    }
  }

  .slick-prev {
    right: 0;
    opacity: 0;

    &:hover {
      &::before {
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none"><path d="M0.430706 10.1587C0.137813 10.4655 0.137813 10.963 0.430706 11.2699C0.7236 11.5767 1.19847 11.5767 1.49137 11.2699L5.59283 6.55559C5.87677 6.25813 5.8867 5.77917 5.61537 5.46908L1.49037 0.754792C1.21048 0.434914 0.73605 0.413303 0.430711 0.706526C0.125372 0.999748 0.104745 1.49677 0.384639 1.81664L4.0244 5.97637L0.430706 10.1587Z" fill="%23FEFCFF"/></svg>');
      }
    }

    &:before {
      display: block;
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none"><path d="M0.430706 10.1587C0.137813 10.4655 0.137813 10.963 0.430706 11.2699C0.7236 11.5767 1.19847 11.5767 1.49137 11.2699L5.59283 6.55559C5.87677 6.25813 5.8867 5.77917 5.61537 5.46908L1.49037 0.754792C1.21048 0.434914 0.73605 0.413303 0.430711 0.706526C0.125372 0.999748 0.104745 1.49677 0.384639 1.81664L4.0244 5.97637L0.430706 10.1587Z" fill="%23858795"/></svg>');
      transform: rotate(-180deg);
      background-color: #F3F6F9;
      font-size: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
    }
  }

  .slick-next {
    right: 0;
    opacity: 0;

    &:hover {
      &::before {
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none"><path d="M0.430706 10.1587C0.137813 10.4655 0.137813 10.963 0.430706 11.2699C0.7236 11.5767 1.19847 11.5767 1.49137 11.2699L5.59283 6.55559C5.87677 6.25813 5.8867 5.77917 5.61537 5.46908L1.49037 0.754792C1.21048 0.434914 0.73605 0.413303 0.430711 0.706526C0.125372 0.999748 0.104745 1.49677 0.384639 1.81664L4.0244 5.97637L0.430706 10.1587Z" fill="%23FEFCFF"/></svg>');
      }
    }

    &:before {
      display: block;
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none"><path d="M0.430706 10.1587C0.137813 10.4655 0.137813 10.963 0.430706 11.2699C0.7236 11.5767 1.19847 11.5767 1.49137 11.2699L5.59283 6.55559C5.87677 6.25813 5.8867 5.77917 5.61537 5.46908L1.49037 0.754792C1.21048 0.434914 0.73605 0.413303 0.430711 0.706526C0.125372 0.999748 0.104745 1.49677 0.384639 1.81664L4.0244 5.97637L0.430706 10.1587Z" fill="%23858795"/></svg>');
      background-color: #F3F6F9;
      font-size: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;

      &:hover {
        background-color: #009EF7;
        color: #fff;
      }
    }
  }

}

.ot-create-popup {

  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events,
  .fc-daygrid-day-events {
    display: none;
  }

  .fc .fc-daygrid-day-top {
    justify-content: center;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    width: 42px;
    margin: 0 auto;
    border-radius: 50%;

    @media screen and (max-width:1279px) {
      width: 30px;
      height: 30px;
    }
  }

  .fc .fc-col-header-cell-cushion {
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    text-transform: capitalize !important;

    @media screen and (max-width:999px) {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .fc .fc-toolbar-title {
    color: #1B3757;
    font-size: 14px;
    font-weight: normal;
  }

  .fc-toolbar-chunk {

    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: none;
      box-shadow: none;
    }
  }

  .fc-prevCustomButton-button:hover .fc-icon,
  .fc-nextCustomButton-button:hover .fc-icon {
    color: #464E5F !important;
  }

  .fc-prevCustomButton-button,
  .fc-nextCustomButton-button,
  .fc-prev-button.fc-button.fc-button-primary,
  .fc .fc-toolbar-chunk:nth-child(3) button {
    background-color: transparent !important;
    border: 0 !important;
    color: #464E5F !important;

    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: none;
      box-shadow: none;
    }
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 0 !important;
  }

  table {
    width: 100% !important;

    tr,
    th {
      background-color: #F5F8FA;
    }

    th {
      padding-bottom: 10px;
    }
  }

  .fc .fc-scrollgrid-section-sticky>* {
    background-color: transparent;
  }

  .fc .fc-daygrid-day.fc-day-today {
    .fc-daygrid-day-frame {
      min-height: 100%;
      position: relative;
      border-radius: 50%;

      // width: 42px;
      // height: 42px;
      // margin: 5px auto;
      .fc-daygrid-day-top {
        background-color: var(--fc-today-bg-color);
        cursor: pointer;
      }

    }
  }

  .fc-daygrid-day-top {
    cursor: pointer;
  }

  .selected-date {
    .fc-daygrid-day-top {
      a {
        color: #fff !important;
      }

      background-color: #3699FF !important;
    }
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: transparent !important;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 12px;
  }
}

.base-Popper-root {
  z-index: 9;
}

.bottom_navigation a {
  img {
    filter: brightness(0) saturate(100%) invert(73%) sepia(7%) saturate(0%) hue-rotate(148deg) brightness(92%) contrast(83%);
  }
}

.bottom_navigation a.active.inactive {
  img {
    filter: none;
  }

  &::before {
    display: none;
  }
}

.bottom_navigation a.active {
  position: relative;

  img {
    filter: brightness(0) saturate(100%) invert(27%) sepia(53%) saturate(1892%) hue-rotate(325deg) brightness(78%) contrast(92%);
  }

  &::before {
    display: block;
    content: '';
    width: 12px;
    height: 2px;
    background-color: #9E3039;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
}

// .rpv-core__page-layer--single{
//   width: 100% !important;
//   &  *{
//     width: 100% !important;
//   }

.fc-direction-ltr .fc-button-group>.fc-button:not(:last-child),
.fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
  background-color: #fff;
  color: #282828;
  border: 0;

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    box-shadow: none;
  }
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: unset !important;
  display: flex;
  justify-content: center;
}

.profile_calendar {

  .fc-theme-standard .fc-scrollgrid,
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 0;
    border-top: 0 !important;
  }

  .fc .fc-daygrid-day-top {
    justify-content: center;
  }
  td.active,
  .fc .fc-daygrid-day.fc-day-today {
    border-radius: 12px;
    background: transparent;

    .fc-daygrid-day-frame {
      border-radius: 12px;
      background: #EEE;
      margin: 0 4px;
      padding-bottom: 0;
    }
  }

  .fc .fc-daygrid-day-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-bottom: 0;
  }

  .fc .fc-daygrid-day-number {
    padding: 0;
    white-space: nowrap;
  }

  .fc-theme-standard td {
    width: 38px;
    height: 38px;
  }
}

.fc .fc-daygrid-event-harness .fc-event.only-one-event-data {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.fc-daygrid-event {
  border-radius: 0;
}

.fc .fc-daygrid-event-harness:first-child .fc-event {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.fc .fc-daygrid-event-harness:last-of-type,
.fc .fc-daygrid-event-harness:nth-child(4) .fc-event,
.fc .fc-daygrid-event-harness:last-child .fc-event {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.fc .fc-daygrid-day-bottom {
  margin: 0;
}

.fc-daygrid-day-bottom {
  display: none;
}

.fc-event-past {
  display: none;
}

.fc-daygrid-day-events .fc-daygrid-event-harness {
  // position: relative;
}

.fc .fc-daygrid-event-harness-abs {
  width: 6px;
}

.fc-daygrid-day-events .fc-daygrid-event-harness {
  top: 0 !important;
  position: relative;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  height: 7px;
  overflow: hidden;
  flex-wrap: wrap;
  pointer-events: none;
}

.fc-day-future.fc-day-other .fc-daygrid-day-events {
  display: none !important;
}

.privacy-policy {
  max-height: 87vh;
  overflow: auto;
}

.privacy-policy * {
  color: #404040;
}

.privacy-policy strong {
  color: #282828 !important;
}

.privacy-policy ol {
  list-style: auto;
  padding-left: 1rem;
}

.privacy-policy ul {
  list-style: inherit;
  padding-left: 1rem;
}

.event-list {
  max-height: 28vh;
}

@media screen and (max-width:480px) and (max-height:550px) {
  .event-list {
    max-height: 18vh !important;
  }
}
.rpv-toolbar__right,
.rpv-toolbar__left{
  display: none !important;
}
.rpv-default-layout__main{
  min-height: 550px;
}
// .rpv-core__inner-container,
// .rpv-core__inner-page,
// .rpv-core__canvas-layer canvas,
// .rpv-core__canvas-layer,
// .rpv-core__text-layer,
// .rpv-core__canvas-layer,
// .rpv-core__page-layer {
//   // width: 100% !important;
//   max-width: 100%;
//   overflow: hidden;
//   // height: 80% !important;
// }

// .rpv-core__page-layer::after {
//   display: none !important;
// }

// .rpv-core__page-layer canvas {
//   //  transform: scale(1.05);
//   width: 100% !important;
//   position: absolute;
//   left: 0;
// }
.rpv-toolbar__center .rpv-core__display--hidden{
  display: block;
}
div[aria-describedby="rpv-core__tooltip-body-zoom-out"],
div[aria-describedby="rpv-core__tooltip-body-zoom-in"]{
  display: flex;
  align-items: center;
}
.zoom-option button{
  height: auto !important;
}
.fade-in-out {
  transition: all .3s;
  width: 0;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  padding-top: 0px;
  &.active {
    width: 100%;
    transition: all .3s;
    z-index: 9999;
  }
}

.login-form input:-webkit-autofill,
.login-form input:-webkit-autofill:hover,
.login-form input:-webkit-autofill:focus,
.login-form input:-webkit-autofill:active {
  border-radius: 9.555px;
  border: 1.194px solid #E2DFBE;
  -webkit-box-shadow: 0 0 0px 1000px #F9F8EE inset;
  transition: background-color 5000s ease-in-out 0s;
}

.fc-daygrid-day-events {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap !important;
  border-radius: 20px;
}

.fc .fc-daygrid-event-harness:first-child .fc-event {
  background-color: transparent !important;
}

.fc-event {
  background-color: transparent !important;
  border-color: transparent !important;
}

.fc-day-past .fc-daygrid-day-events {
  display: none !important;
}

.logout-container {
  height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form {
  max-height: calc(100vh - 50px);
  margin-top: -20px;
}
/* src/components/BlurOverlay.css */
.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8); /* semi-transparent white */
  backdrop-filter: blur(10px);
  display: none; /* hidden by default */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.blur-overlay.active {
  display: flex; /* show when active */
}

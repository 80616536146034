.activeReportChart{
    width: 27px;
    height: 27px;
    background-color: #8950FC;
    margin-right: 10px;
    border-radius: 6px;
    @media screen and (max-width:1025px) {
        width: 25px;
        height: 25px;
    }
    cursor: pointer;
    svg{        
        width: 16px;
        height: 16px;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}
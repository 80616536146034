.resizer.isResizing {
  background: rgb(78, 119, 232);
  opacity: 1;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #abb577;
  ;
  user-select: none;
  touch-action: none;
  cursor: col-resize;

}

.horizontalScrollBar {
  table{
    border-collapse: collapse !important;
  }
  // max-width: 2000px;
  & {
    --thumbBG: #c4c4c4;
    --scrollbarBG: #fff;
  }

  &::-webkit-scrollbar {
    height: 10px;
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }

  // th {
  //   // width: 200px;
  //   // min-height: 45px;
  //   // height: 45px;
  // }

  td {
    // width: 200px;
    min-height: 35px;
    height: auto;
  }

  th {
    // width: 300px;
    // min-width: 300px;
    // min-height: 45px !important;
    // height: auto !important;
    // padding: 0.45rem !important;
    min-width: 150px;
    padding: 0;

    >div {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }

  td {
    border-top: 0 !important;
    // min-width: 300px;
    // width: 300px;
    min-height: 35px !important;
    max-height: 35px !important;
    //height: 35px !important;
    height: 35px !important;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    border-bottom: 0.5 solid #c2c5c6;
    border-top: none !important;

    // max-width: 200px !important;
    >div> {
      max-width: 180px;
      overflow: hidden;
      margin-top: 0 !important;

      button {
        margin-top: 0 !important;
        height: 100%;
        // min-height: 24px;
        height: 24px;
        // width: 24px;
      }

      //text-overflow: ellipsis;
      white-space: nowrap;

      // min-width: 200px !important;
      >div {
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    >div {
      height: 100%;

      >div {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    // padding-left: 0.5rem !important;
    // padding-right: 0.5rem !important;
  }

  td:last-child.absolute {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  th.absolute {
    padding: 0 !important;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      padding: 0.45rem !important;
    }

  }

  td[id*="review"] {
    button {
      height: auto !important;
    }
  }
  th[id*="selection"],
  td[id*="selection"]{
    width: 35px !important;
    max-width: 35px;
    min-width: 35px !important;
  }
  th[id*="actions"],
  td[id*="actions"] {
    min-width: 120px;
    max-width: 120px;
    right: 0 !important;
    left: unset !important;
    background-color: #fff;
    z-index: 9 !important;
    padding-left: 0;
    padding-right: 0;
    position: sticky !important;

    button {
      height: 24px !important;
      width: 24px !important;
    }
  }

  th[id*="actions"] {
    >div {
      padding-left: 10px !important;
    }
  }

  th[id*="lasted"],
  td[id*="lasted"] {
    width: 120px;

    >div {
      padding-left: 10px !important;
    }

  }

  th[id*="no-lasted"],
  td[id*="no-lasted"] {
    display: none;
  }

  td[id*="actions"]>div {
    height: 100%;

    button {
      z-index: 9 !important;
    }
  }

  // th[id*="age"],
  // td[id*="age"] {
  //   // max-width: 100px !important;
  // }

  // th[id*="years"],
  // td[id*="years"] {
  //   // max-width: 130px !important;
  // }

  // th[id*="Manager"],
  // td[id*="Manager"] {
  //   max-width: 132px !important;
  // }

  th[id*="paymentStage"],
  td[id*="paymentStage"] {
    max-width: 400px !important;
    min-width: 400px !important;

    >div:first-child {
      max-width: 100% !important;
      min-width: unset !important;

      >div {
        max-width: 100% !important;
      }
    }
  }

  // th[id*="none"],
  // td[id*="none"] {
  //   //display: none;
  // }
}

.overlayLayer {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.table-customize {
  position: relative;
  display: inline-block;
}

.customWidth {
  width: 90%;
}
.popupClose{
    svg {
        width: 10px;
        height: 10px;
        path{
            fill: #B5B5C3;
        }
    }
}
.customBoxshadow{
    box-shadow: 0 1px 10px 0 rgba(0,0,0,.1), 0 2px 15px 0 rgba(0,0,0,.05);
}
.clockingIn {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.customUploadBtn{
  background-color: rgba(0, 0, 0, 0.5);
  svg{
    width: 100%;
    height: 100%;
  }
}
.profileImageDiv{
  &:hover{
    .customUploadBtn{
      display: block;
    }
  }
}

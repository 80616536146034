.customSwitch {
  &::before {
    position: absolute;
    display: block;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 100%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

.customSwitchContainer {
  display: flex;
  align-items: center;
  height: 100%;

  input:checked+.customSwitch {
    background-color: #2196F3;
  }

  input:focus+.customSwitch {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked+.customSwitch:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
}

.PermissionTable {
  th {
    white-space: normal;
    // max-width: 200px;
    // width: 200px;
    color: #858795 !important;
  }

  th[id*="permission"] {
    border-bottom: none;
  }

  td[id*="permission"],
  th[id*="permission"] {
     max-width: 280px !important;
     min-width: 280px !important;
    position: sticky;
    // width: 100%;
    left: 0;
    z-index: 1;
    background-color: #fff;
    min-height: 51px;
    // height: 100%;
  }

  th[id*="permission"]>div {
    height: 52px;
  }

  // td[id*="none0"],
  // th[id*="none0"]{
  //   width: 220px !important;
  //   min-width: 220px !important;
  //   display: table-cell !important;

  // }
  td {
    p {
      //height: 100%;
      height: auto;
      display: inline-block;
      align-items: center;
    }

    // width: 200px;
    // min-height: 69px !important;
    //height: 69px !important;
  }

  th,
  td {
    max-width: 132px !important;
  }

  th {
    height: 52px;
  }
}

.permissionDropdown {
  >div {
    min-width: 250px;

    @media screen and (max-width:481px) {
      min-width: max-content;
    }
  }

  button {
    max-width: 100%;
    width: 100%;
    padding: 5px 11px;
    overflow: hidden;

    @media screen and (max-width:481px) {
      max-width: 100%;
      width: 100%;
      word-break: break-word;
      text-align: left;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media screen and (max-width:481px) {
    width: 100%;

    >div {
      width: 100%;
    }
  }
}

.progress {
  svg {
    height: 90vh;
    margin: auto;
    display: block;
  }

  path {
    stroke-linecap: round;
    stroke-width: 6;
  }

  path.grey {
    stroke: #e7e7e8;
  }

  path.blue {
    stroke: url(#gradient);
    stroke-dasharray: 198;
    stroke-dashoffset: 198;
    animation: dash 3s ease-out forwards;
  }
}

.userTables td>div>div {
  max-width: 100% !important;
}

.userTables {
  td[id*="roles"] {
    padding: 0 !important;
  }
}

.userTables {
  td[id*="action"] {
    min-height: 35px !important;
    max-height: 38px !important;
    height: 38px !important
  }
}

.userTables {
  td {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.leaveDatePicker {
  @media screen and (max-width: 360px) {
    & button {
      width: 100%;
    }
  }
}

table {

  th:last-child[id*="actions"],
  td:last-child[id*="actions"] {
    min-width: 120px;
    width: 120px;
    position: sticky !important;
    right: 0;
    background-color: #fff;
  }
}

// .personalLeaveTable,
.personalLeaveBalance {
  th {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;

    table {

      th:last-child[id*="actions"],
      td:last-child[id*="actions"] {
        min-width: 120px;
        width: 120px;
        position: sticky !important;
        right: 0;
        background-color: #fff;
      }
    }
  }
}



//    th {
//      padding-top: 0.5rem !important;
//      padding-bottom: 0.5rem !important;
//    }
// }

.customEdit svg {
  path {
    fill: #000;
  }
}

.uploadBtn {
  background: #EFEFEF;
  font-size: 12px;
  font-family: "poppins", sans-serif;
  color: #858795;
  padding: 3px;
  border-radius: 4px;
  border: 1px solid#C2C2C2;
}




.attendance1Table {
  table {
    th:first-child[id*="Leave Type"] {
      background-color: #f3f6f9 !important;
    }

    th:first-child[id*="Leave Type"],
    td:first-child[id*="0"],
    td:first-child[id*="1"],
    td:first-child[id*="2"],
    td:first-child[id*="3"],
    td:first-child[id*="4"],
    td:first-child[id*="5"],
    td:first-child[id*="6"],
    td:first-child[id*="7"],
    td:first-child[id*="8"],
    td:first-child[id*="9"],
    td:first-child[id*="10"] {
      min-width: 120px;
      width: 120px;
      position: sticky !important;
      left: 0;
      outline: 1px solid rgb(194, 197, 198);
      background-color: white;
    }

  }

  // tbody td[id*="Leave Type"] {

  th:first-child[id*="Leave Type"],
  td:first-child[id*="0"],
  td:first-child[id*="1"],
  td:first-child[id*="2"],
  td:first-child[id*="3"],
  td:first-child[id*="4"],
  td:first-child[id*="5"],
  td:first-child[id*="6"],
  td:first-child[id*="7"],
  td:first-child[id*="8"],
  td:first-child[id*="9"],
  td:first-child[id*="10"] {
    border-right: 0 !important;
    border-left: 0.5px solid #C4C4C4 !important;

    // &:first-child:before {
    //     display: block;
    //     content: '';
    //     border-left: 0.5px solid #C4C4C4;
    //     position: absolute;
    //     left: 0;
    //     height: 100%;
    //     top: 0;
    // }

    // &::after {
    //   display: block;
    //   content: '';
    //   border-right: 0.5px solid #C4C4C4;
    //   position: absolute;
    //   right: 0;
    //   height: 100%;
    //   top: 0;
    // }
  }

}
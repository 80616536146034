.memberDropdownList{
    min-height: 48px;
    .customDropdownContainer{
        width: 100%;
        
    }
}
.customConfirmBtn{
    &:hover{
        svg{
            path{
                fill: #3699FF;
            }
        }
    }
}
.cancelBtn{
    svg{
        width: 10px;
        height: 10px;
        margin-right: 5px;
        path{
            fill: #464E5F;
        }
    }
}
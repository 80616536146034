.customCheckbox {
    display: block;
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        left: 0;
    }
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #eee;
    border-radius: 4px;
}

.customCheckbox input:checked~.checkmark {
    background-color: #2196f3;
    border-radius: 4px;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.customCheckbox input:checked~.checkmark:after {
    display: block;
}

.customCheckbox .checkmark:after {
    left: 35%;
    top: 50%;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg) translate(-50%, -50%);
    -ms-transform: rotate(45deg) translate(-50%, -50%);
    transform: rotate(45deg) translate(-50%, -50%);
}

.selectRoot:focus-visible,
.selectRoot:focus,
.selectRoot::after,
.selectRoot:before {
    border-bottom: none !important;
    transition: none !important;
    outline: none !important;
}

.selectRoot option {
    padding: 0.5rem 0.75rem;
}

.selectRoot select {
    padding: 0.5rem 0.75rem;
    height: auto !important;
    border-radius: 6px;
    font-size: 0.75rem;
    color: #858795;
    font-weight: 600;
}

.selectRoot select:focus {
    background-color: #f3f6f9 !important;
    border-radius: 6px;
}

.cusOption {
    padding: 0.5rem 0.75rem;
}
.eventChildLeave,
.eventLeftOver,
.eventSubstitute,
.eventPaternity,
.eventMaternity,
.birthday,
.sick,
.unpaid,
.annualLeave {
    &::before {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: block;
        content: "";
        margin-right: 12px;

        @media screen and (max-width:640px) {
            width: 10px;
            height: 10px;
            min-width: 10px;
            min-height: 10px;
            margin-right: 10px;
        }
    }
}

.birthday {
    &::before {
        background-color: #25d1c9;
    }
}

.sick {
    &::before {
        background-color: #f64e60;
    }
}

.unpaid {
    &::before {
        background-color: #858795;
    }
}



.annualLeave {
    &::before {
        background-color: #3699ff;
    }
}

.eventClientUAT,
.eventSEO,
.clientUat,
.eventDevelopment,
.eventKOM,
.design,
.uat,
.launch {
    &::before {
        width: 1rem;
        height: 1rem;
        border-radius: 2px;
        margin-right: 0.5rem;
        display: block;
        content: "";
        @media screen and (max-width:640px) {
            width: 10px;
            height: 10px;
            min-width: 10px;
            min-height: 10px;
            margin-right: 10px;
            top: 2px;
            position: relative;
        }
    }
}

.design {
    &::before {
        background-color: #199791;
    }
}
.eventMaternity {
    &::before {
        background-color: #00DB92;
    }
}
.eventLeftOver{
    &::before {
        background-color: #FFA800;
    }
}
.eventChildLeave{
    &::before {
        background-color: #ABBC57;
    }
}
.eventSubstitute{
    &::before {
        background-color: #199791;
    }
}
.eventPaternity{
    &::before{
        background-color: #8950FC;
    }
}
.uat {
    &::before {
        background-color: #DE4296;
    }
}

.eventClientUAT,
.clientUat {
    &::before {
        background-color: #526AE5;
    }
}
.eventLaunchDay,
.launch {
    &::before {
        background-color: #FFA800;
    }
}

.eventKOM {
    &::before {
        background-color: #FFD058;
    }
}

.eventDevelopment {
    &::before {
        background-color: #CA73E0 !important;
    }
}

.eventSEO {
    &::before {
        background-color: #f64e60 !important;
    }
}

.relative {
    position: relative;
}

.selectedEventType {
    &::after {
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #c4c4c4;
    }
}

.selectedEventType {
    font-family: "Poppins", sans-serif !important;
    font-size: 0.75rem;
    font-weight: 600;
}

.eventTypeCreatePopup {
    background-color: rgba(0, 0, 0, 0.55);
}

.customCalendarDropdown {
    @media screen and (max-width: 361px) {
        top: 40px !important;
        left: 0 !important;
    }
}

.horbar {
    &::after {
        width: 100%;
        height: 1px;
        content: '';
        display: block;
        background-color: #F3F6F9;
        margin-top: 12px;
    }
}

.saveButton {
    &:hover {
        .defaultIcon {
            display: none;
        }

        .hoverIcon {
            display: block;
        }
    }
}

.addNewPopup {
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 99;
}

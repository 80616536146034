.filterFileType {
  div > div {
    border-radius: 4px;
    button {
    //  padding: 10px;
      color: #464e5f;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
    }
  }
}

.leaveDatePicker {
  @media screen and (max-width: 360px) {
    & button {
      width: 100%;
    }
  }
}

.customChevronColor {
  & > svg > path {
    fill: #b6b6b6;
  }
}

.downloadButtonContainer > button {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 7px 6px;
}
.ProfileDocTable{
  th[id*='name']
  th[id*='name']{
    width: 200px !important;
  }
  th[id*='lasted']
  td[id*='lasted']{
    width: 120px !important;
    min-width: 120px !important;
  }
}
.setting_arrow{
    position: relative;
    &::after{
        display: block;
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16" fill="none"><path d="M1.63466 4.38666L2.34199 3.67999L6.19466 7.53133C6.25676 7.59304 6.30605 7.66642 6.33968 7.74726C6.37331 7.82809 6.39063 7.91478 6.39063 8.00233C6.39063 8.08988 6.37331 8.17656 6.33968 8.2574C6.30605 8.33823 6.25676 8.41161 6.19466 8.47333L2.34199 12.3267L1.63532 11.62L5.25132 8.00333L1.63466 4.38666Z" fill="%23282828"/></svg>');
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
.active_language{
    position: relative;
    &::after{
        display: block;
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M6.99967 0.333374C3.33301 0.333374 0.333008 3.33337 0.333008 7.00004C0.333008 10.6667 3.33301 13.6667 6.99967 13.6667C10.6663 13.6667 13.6663 10.6667 13.6663 7.00004C13.6663 3.33337 10.6663 0.333374 6.99967 0.333374ZM5.66634 10.3334L2.33301 7.00004L3.27301 6.06004L5.66634 8.44671L10.7263 3.38671L11.6663 4.33337L5.66634 10.3334Z" fill="%239E3039"/></svg>');
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
.customDropdown {
  &>div {
    @media screen and (max-width: 768px) {
      width: 100%;

      & button {
        width: 100%;
      }
    }
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
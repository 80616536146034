:root {
    --event-color: '';
    --min-vw: 640;
    --max-vw: 1920;

    /* convert to px */
    --min-vw-px: calc(var(--min-vw) * 1px);
    --max-vw-px: calc(var(--max-vw) * 1px);

    /* font sizes at --max-vw */
    --text-h1: 20;
    --text-h2: 16;
    --text-h3: 16;
    --text-button: 14;
    --text-body-md: 14;
    --text-body-sm: 12;
    --text-caption: 9;
    --text-base: 16;
    --text-body-28: 28;
    /* font sizes at --min-vw */
    --text-h1-min: calc(var(--text-h1) * 1);
    --text-h2-min: calc(var(--text-h2) * 1);
    --text-h3-min: calc(var(--text-h3) * 1);
    --text-button-min: calc(var(--text-h1) * 1);
    --text-body-md-min: calc(var(--text-h2) * 1);
    --text-body-sd-min: calc(var(--text-h3) * 1);
    --text-caption-min: calc(var(--text-h3) * 1);
    --text-body-28-min: calc(var(--text-body-28) * 1);
    --text-base-min: 14;
    --text-19: 19;
    --scrollbar-width: 0;
}

body {
    // overflow: hidden;
    padding-right: var(--scrollbar-width);
}

.fluid-fs {
    --max-fs: var(--text-base);
    --min-fs: var(--text-base-min);

    --slope: calc((var(--max-fs) - var(--min-fs)) / (var(--max-vw) - var(--min-vw)));

    --min-fs-px: calc(var(--min-fs) * 1px);
    --max-fs-px: calc(var(--max-fs) * 1px);
    --fs-px: calc(var(--slope) * (100vw - var(--min-vw-px)) + var(--min-fs-px));

    font-size: clamp(var(--min-fs-px), var(--fs-px), var(--max-fs-px));
}

.heading1 {
    // @apply fluid-fs;

    // --max-fs: var(--text-h1);
    // --min-fs: var(--text-h1-min);
    line-height: 135%;
    font-family: "Arial";
    font-size: calc(20px * 1.194);
}

.heading2 {
    // @apply fluid-fs;

    // --max-fs: var(--text-h2);
    // --min-fs: var(--text-h2-min);
    font-family: "Arial";
    font-size: 19.11px;
}

.heading3 {
    // @apply fluid-fs;

    // --max-fs: var(--text-h3);
    // --min-fs: var(--text-h3-min);
    font-family: "Arial";
    font-size: 19.11px;
}

.text-button {
    // @apply fluid-fs;

    // --max-fs: var(--text-button);
    // --min-fs: var(--text-button);
    font-family: "Arial";
    font-size: 14.333px;
}

.text-body-md {
    // @apply fluid-fs;

    // --max-fs: var(--text-body-md);
    // --min-fs: var(--text-body-md);
    font-family: "Arial";
    font-size: 16.722px;
}

.text-body-sm {
    // @apply fluid-fs;

    // --max-fs: var(--text-body-sm);
    // --min-fs: var(--text-body-sm);
    font-family: "Arial";
    font-size: 14.333px;
}

.text-caption {
    // @apply fluid-fs;

    // --max-fs: var(--text-caption);
    // --min-fs: var(--text-caption-min);
    font-family: "Arial";
    font-size: 9px;
}

.text-body-28 {
    // @apply fluid-fs;

    // --max-fs: var(--text-body-28);
    // --min-fs: var(--text-body-28-min);
    font-family: "Arial";
    font-size: 28px;
}

.text-body-19 {
    font-size: 19.11px;
}

.text-body-16 {
    font-size: 16.72px;
}

.text-body-14 {
    font-size: 14.333px;
}

.text-body-23 {
    font-size: 23.888px;
}

.profile_calendar {
    .fc-toolbar-title {
        font-size: 20px;
        min-width: 220px;
        //   @apply text-body-28 min-w-[220px];
    }

    .fc .fc-daygrid-day-number {
        font-size: 14px;
        font-weight: normal;
        color: #282828;
        // @apply text-body-sm font-normal text-[#282828];
    }

    .fc .fc-col-header-cell-cushion {
        font-size: 14px;
        font-weight: 700;
        color: #282828;
        // @apply text-body-sm font-bold text-[#282828];
    }

    th.fc-day-sat a,
    th.fc-day-sun a {
        color: #9E3039;
    }

    .fc-theme-standard th {
        border-bottom: 1px solid #E4E4E4;

        a {
            padding-bottom: 17px;
        }
    }

    .fc .fc-day-other .fc-daygrid-day-top {
        opacity: 0;
    }
}

@media screen and (min-width:430px) {
    .heading1 {
        font-size: calc(20px * 1.194);
    }
    .heading3,
    .text-body-md {
        font-size: calc(16px * 1.194);
    }
    .text-body-sm {
        font-size: calc(14px * 1.194);
    }
    .text-body-28{
        font-size: calc(28px * 1.194);
    }
}
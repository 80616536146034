.profileEditTextInput {
  border: none;
  background: #f3f6f9;
  padding: 13px 16px;
  font-size: 14px;
  line-height: 21px;
  color: #464e5f;
  width: 100%;
  border-radius: 6px;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
}

.profileEditLabel {
  font-size: 15px;
  font-weight: 500;
  line-height: 22.5px;
  color: #464e5f;
  display: block;
  margin-top: auto;
  margin-bottom: auto;

  @media screen and (max-width:1281px) {
    font-size: 13px;
  }
}

.profileDisplay {
  max-width: 898px;

  &__row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    color: #464e5f;
    padding-left: 16px;
    padding-right: 16px;

    &>h4 {
      // font-size: 13px;
      margin-bottom: 8px;
      font-weight: 500;
      line-height: 22px;
    }

    &>p {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }

    @media (min-width: 320px) {
      padding-left: 8px;
      padding-right: 8px;

      // &>h4 {
      //   // font-size: 15px;
      // }

      // &>p {
      //   //font-size: 14px;
      // }
    }

    @media (min-width: 360px) {
      padding-left: 16px;
      padding-right: 16px;

      // &>h4 {
      //   // font-size: 15px;
      // }

      // &>p {
      //   //font-size: 14px;
      // }
    }

    @media (min-width: 640px) {
      padding-left: 20px;
      padding-right: 20px;

      // &>h4 {
      //   // font-size: 15px;
      // }

      // &>p {
      //   //font-size: 14px;
      // }
    }


  }

  &__header {
    border: 1px solid #f3f6f9;
    padding-top: 18px;
    padding-bottom: 18px;
    margin-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;

    &>h4 {
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;
      color: #464e5f;
    }

    @media (min-width: 640px) {
      padding-left: 20px;
      padding-right: 20px;

      &>h4 {
        font-size: 15px;
      }
    }

    @media screen and (min-width: 1360px) {
      &>h4 {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}

.countryDropdown {
  width: 100%;

  &>div>button {
    width: 100%;
    border: transparent;
    background-color: #f3f6f9;
    border-radius: 6px;
    color: #464e5f;

    &>svg>path {
      fill: #464e5f;
    }
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.customTable {
  &>div>table>thead>tr>th>div>button>svg {
    &>path {
      fill: #858795;
    }
  }

  td[id*="createdBy"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.overlayLayer {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.selectedMember {
  background-color: #E1F0FF !important;
}

.customTable table td,
.customTable td[id*="lasted"],
.customTable td[id*="lasted-no"],
.customTable td[id*="actions"],
.customTable td[id*="none-actions"] {
  max-height: 38px !important;
  min-height: 38px !important;
  height: 38px !important;
}


.modal {
  width: 400px;
  height: 250px;
  color: #fff;
  background: #79767c;
  padding: 40px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  position: absolute;
  z-index: 100;
  top: calc(50% - 145px);
  left: calc(50% - 220px);
  display: flex;
  flex-direction: column;
}


input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
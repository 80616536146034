.bg_image{
  max-height: 20vh;
}
@media screen and (max-height:668px) {
  .bg_image{
    max-height: 30vh;
  }
}
@media screen and (max-height:667px) {
  .bg_image{
    max-height: 15vh;
  }
}
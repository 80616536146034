.customUploadBtn{
    background-color: rgba(0, 0, 0, 0.5);
    svg{
      width: 100%;
      height: 100%;
    }
  }
  .profileImageDiv{
    &:hover{
      .customUploadBtn{
        display: block;
      }
    }
  }
  
.otListTable {

    th[id*="lasted"],
    td[id*="lasted"] {
        min-width: 140px !important;
        width: 140px;

        >div {
            height: 100%;
        }
    }

    th[id*="actions"],
    td[id*="actions"] {
        min-width: 140px !important;
        width: 140px;
        position: absolute !important;
        right: 0;
        left: unset !important;
        background-color: #fff;
        z-index: 1;
    }
}

.verticalLine {
    display: flex;
    &:after {
        width: 2px;
        height: 100%;
        background-color: #F5F5F5;
        display: block;
        content: '';
        margin-left: 16px;
    }
}

// .otCreatedPopup{
//     z-index: 9999;
// }

.uploadBtn {
    background: #EFEFEF;
    font-size: 12px;
    font-family: "poppins", sans-serif;
    color: #858795;
    padding: 3px;
    border-radius: 4px;
    border: 1px solid#C2C2C2;
  }
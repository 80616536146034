input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
  display: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
  display: none;
}


table {

  th:last-child[id*="actions"],
  td:last-child[id*="actions"] {
    min-width: 120px;
    width: 120px;
    position: sticky !important;
    right: 0;
    background-color: #fff;
  }
}

.myPasswordTable {

  table tr td:nth-child(2),
  table tr th:nth-child(2) {
    border-left: 0.5px solid #C4C4C4 !important;
  }

  td>div {
    height: auto;
  }

  th:last-child[id*="action"],
  td:last-child[id*="action"] {
    right: 0.5px !important;
  }
  th[id*="headernone-actions"],
  td[id*="none-actions"] {
    display: none;
  }
  
  th:last-child[id*="action"],
  td:last-child[id*="action"] {
    min-width: 120px;
    width: 120px;
    position: sticky !important;
    right: 0;
    background-color: #fff;
    border: 0.5px solid #C4C4C4 !important;

    &::before {
      display: block;
      content: '';
      border-left: 0.5px solid #C4C4C4;
      position: absolute;
      left: 0;
      height: 100%;
      top: 0;
    }
    &::after {
      display: block;
      content: '';
      border-right: 0.5px solid #C4C4C4;
      position: absolute;
      right: 0;
      height: 100%;
      top: 0;
    }
  }
}

th:last-child[id*="actions"],
td:last-child[id*="actions"] {
  min-width: 120px;
  width: 120px;
  position: sticky !important;
  right: 0;
  background-color: #fff;
}

.myPasswordTable {
  table {

    th:nth-child(2),
    td:nth-child(2) {
      border-left: 0.5px solid rgb(194, 197, 198);
    }
  }
}
.custom_timepicker {
    min-width: unset !important;
    margin-bottom: 5px !important;

    textarea:focus,
    input:focus {
        outline: none;
    }

    & .MuiTextField-root {
        border-color: none !important;
        outline: none !important;
        border: none !important;
    }



    .MuiFormControl-root .MuiTextField-root {
        outline: none !important;
        border: none !important;
    }



    input {
        border: none !important;
        padding: 13px !important;
        font-family: 'Poppins';
        font-size: 15px !important;
        color: #8f8e8c !important;
        /* Adjust the padding value according to your preference */
        background-color: transparent !important;
    }

    input:focus {
        outline: none !important;
    }

    .MuiStack-root {
        padding-top: 0px !important;
        /* Adjust the top padding value as needed */
    }
}

.custom_timepicker *:focus-visible,
.custom_timepicker *:focus-within {
    outline: none !important;
}

.time_picker_container {
    padding-top: 0 !important;
}



@media only screen and (max-width: 1000px) {
    .custom_timepicker {
        input {
            font-size: 12px !important;
            padding: 15px !important;

        }
    }
}

.timepickerContainer * {
    border: 0 !important;
}

.custom_timepicker input {
    border: 1px solid #C4C4C4 !important;
    outline: none !important;
}

.border-zinc-200 {
    border: 1px solid #C4C4C4 !important;
}
.myTimeSheet table{
    td{
        font-weight: normal;
    }
    td[id*="clock"]{
        padding-left: 0 !important;
       padding-right: 0 !important;
    }
    td:nth-child(3),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(9){
       // background-color: #F6FFFA;       
    }
    td:nth-child(4),
    td:nth-child(7),
    td:nth-child(10){
        //background-color: #FEF4F4;
    }
    th{
      //  font-size: 0.813rem;
        font-weight: 600;
        color: #858795;
    }
}
.myTimeSheetCustom{
    th{
        // pointer-events: none !important;
    }
}
// .customDropdownScroll::-webkit-scrollbar {
//     width: 5px;
//     border-radius: 5px;
//     // background-color: #CACACA;
//     background-color: #508ef2;
// }

// .customDropdownScrolly::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 2px #CACACA;
// }

// .customDropdownScroll::-webkit-scrollbar-thumb {
//     background-color: #c3bfc3;
//     // background: #669aed;
//     border-radius: 5px;
// }

.isActiveClass {
    background: red;
    color: blue;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.no_scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no_scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
.customDropdownScroll::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  .customDropdownScroll::-webkit-scrollbar-track {
    background: #CACACA; 
  }
   
  /* Handle */
  .customDropdownScroll::-webkit-scrollbar-thumb {
    background: #508ef2; 
    height: 50px;
  }
  
  /* Handle on hover */
  .customDropdownScroll::-webkit-scrollbar-thumb:hover {
    background: #CACACA; 
  }
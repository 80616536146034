.leftsidebar {
    max-width: 312px;
    background-color: #fff;
}

.hiddenData {
    transition: all 0.35s;
    transform: translateX(-100%);
    width: 0;
    opacity: 0;
}

.openData {
    transition: all 0.35s;
    width: 100%;
    opacity: 1;
}

.defaultWidth {
    transition: all 0.35s;
    // width: 100%;
    opacity: 1;
}

.leftsideHide {
    margin-left: 0px !important;
    transition: all 0.35s;

    @media screen and (max-width:999px) {
        margin-left: 0 !important;
    }

}

.handleMenuCloseImg {
    display: block !important;
    width: 24px;
    position: relative;
    top: 0;
    padding-top: 0;
    // height: 65px;
    background-color: #fff;

    @media screen and (max-width:999px) {
        position: relative;
    }
}

.leftSidebar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }
}

.menuActive {
    background-color: rgba(54, 153, 255, 0.15);

    p {
        color: #3699FF;
    }
}

.submenuActive {
    color: #3699FF !important;
}

.subMenuTitle {
    display: flex;
    align-items: center;

    &::before {
        content: '';
        display: block;
        width: 1px;
        height: 20px;
        background-color: #F3F6F9;
        margin-right: 8px;
    }
}

// .hidescrollbar {}

.toggleContent {
    opacity: 1;
    transition: all 0.36s;
    visibility: visible;

}

.toggleContentHide {
    opacity: 0;
    transition: all 0.38s;
    visibility: hidden;
    height: 0;
}

.customInfoBox {
    box-shadow: 0px 16px 21px rgba(0, 0, 0, 0.15);
}

.notificationIcon {
    @media screen and (max-width:571px) {
        svg {
            width: 20px;
            height: 20px;
        }
    }
}
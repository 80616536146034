.customLeaveDatepicker {
    
    margin-left: 5px;

    button span {
        padding-left: 9px;
        padding-right: 0px;
        color: #464E5F !important;
    }
}

.customLeaveAllowanceContent {
    .customNumber {

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            // -webkit-appearance: none;
            // margin: 0;
            opacity: 0 !important;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }

        position: relative;

        &::before {
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #464E5F;
            position: absolute;
            top: 11px;
            right: 11px;
            pointer-events: none;
        }

        &::after {
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #464E5F;
            position: absolute;
            bottom: 11px;
            right: 11px;
            pointer-events: none;
        }
    }
}